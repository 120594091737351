import React from "react";
import Carousel from "react-slick";

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  speed: 5000,
  autoplaySpeed: 1000,
  cssEase: "linear",
};

const settingsM = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 2000,
  cssEase: "linear",
};

const PricingOne = () => {
  return (
    <>
      <Carousel className="price-media" {...settings}>
        <div className="price-img">
          <img className="img" src="../images/Image-01.png" alt="" />
        </div>
        <div className="price-img">
          <img className="img" src="../images/Image-02.png" alt="" />
        </div>
        <div className="price-img">
          <img className="img" src="../images/Image-01.png" alt="" />
        </div>
        <div className="price-img">
          <img className="img" src="../images/Image-02.png" alt="" />
        </div>
      </Carousel>

      <Carousel className="price-mobile" {...settingsM}>
        <div className="price-img">
          <img className="img" src="../images/Image-01.png" alt="" />
        </div>
        <div className="price-img">
          <img className="img" src="../images/Image-02.png" alt="" />
        </div>
        <div className="price-img">
          <img className="img" src="../images/Image-01.png" alt="" />
        </div>
        <div className="price-img">
          <img className="img" src="../images/Image-02.png" alt="" />
        </div>
      </Carousel>
    </>
  );
};

export default PricingOne;

// import PricingData from "../../data/pricing/PricingData.json";
// import { Link } from 'react-router-dom';{ useState, useEffect, }
// import { FaCheck } from "react-icons/fa";
// import Carousel from 'react-elastic-carousel';

//const getPriceingData = PricingData;

// const pricedata = [
//         {
//             title: "Professional",
//             subtitle: "A beautiful, simple website",
//             image: require("../public/images/Image-01.png")
//         }
// ]

// const billed = [
//     {
//         id: 1,
//         label: "Billed Yearly"
//     },
//     {
//         id: 2,
//         label: "Billed Monthly"
//     }
// ]

// ----------
// const [isActive, setActive] = useState("");

//     useEffect(() => {
//         setActive(billed[0].label.toLowerCase());

//     }, []);

//     // eslint-disable-next-line
//     const handleChange = (e) => {
//         setActive(e.target.textContent.toLowerCase());

//     };

// ------------

//  {/* <Carousel enableAutoPlay autoPlaySpeed={1500} itemsToShow={2} infiniteLoop={true}> */}
//                 {/* {getPriceingData.map((data, index) => (
//                     <div className="col-lg-10" key={index}>
//                         <div className="pricing-table pricing-borderd">
//                             <div className="pricing-header">
//                                 <h3 className="title">{data.title}</h3>
//                                 <span className="subtitle">{data.subtitle}</span>
//                                 <div className='price-img'><img className='img' src='../images/Image-01.png' alt="" /></div>
//                                 <div className="price-wrap">
//                                     <div className="yearly-pricing">{data.image}
//                                         <span className="amount">{billed[0].label.toLowerCase() === isActive ? data.yprice : data.mprice }</span>
//                                         <span className="duration">{billed[0].label.toLowerCase() === isActive ? data.yduration : data.mduration }</span>
//                                     </div>
//                                 </div>
//                                 <div className="pricing-btn">
//                                     <Link to="#" className="axil-btn btn-large btn-borderd">Get Started Today</Link>
//                                 </div>
//                             </div>
//                             <div className="pricing-body">
//                                 <ul className="list-unstyled">
//                                     {data.facility.map((data, index) => (
//                                         <li key={index}><FaCheck /> {data}</li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>

//                 ))} */}

//                 {/* <Carousel enableAutoPlay autoPlaySpeed={1500} itemsToShow={2} infiniteLoop={true}> */}
//                 {/* {getPriceingData.map((data, index) => (
//                     <div className="col-lg-10" key={index}>
//                         <div className="pricing-table pricing-borderd">
//                             <div className="pricing-header">
//                                 <h3 className="title">{data.title}</h3>
//                                 <span className="subtitle">{data.subtitle}</span>
//                                 <div className="price-wrap">
//                                     <div className="yearly-pricing">
//                                         <span className="amount">{billed[0].label.toLowerCase() === isActive ? data.yprice : data.mprice}</span>
//                                         <span className="duration">{billed[0].label.toLowerCase() === isActive ? data.yduration : data.mduration}</span>
//                                     </div>
//                                 </div>
//                                 <div className="pricing-btn">
//                                     <Link to="#" className="axil-btn btn-large btn-borderd">Get Started Today</Link>
//                                 </div>
//                             </div>
//                             <div className="pricing-body">
//                                 <ul className="list-unstyled">
//                                     {data.facility.map((data, index) => (
//                                         <li key={index}><FaCheck /> {data}</li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>

//                                     ))} */}

//   {/* </Carousel> */}

//             {/* <div className="pricing-billing-duration">
//                 <ul>
//                     {billed.map((data) => (
//                         <li className="nav-item" key={data.id}>
//                             <button onClick={handleChange} className={`nav-link ${data.label.toLocaleLowerCase() === isActive ? "active": ""}`}>{data.label}</button>
//                         </li>
//                     ))}
//                 </ul>
//             </div> */}

//             {/* <div className="row">
//             <Carousel itemToShow={2}>
//                 {getPriceingData.map((data, index) => (
//                     <div className="col-lg-4" key={index}>
//                         <div className="pricing-table pricing-borderd">
//                             <div className="pricing-header">
//                                 <h3 className="title">{data.title}</h3>
//                                 <span className="subtitle">{data.subtitle}</span>
//                                 <div className="price-wrap">
//                                     <div className="yearly-pricing">
//                                         <span className="amount">{billed[0].label.toLowerCase() === isActive ? data.yprice : data.mprice }</span>
//                                         <span className="duration">{billed[0].label.toLowerCase() === isActive ? data.yduration : data.mduration }</span>
//                                     </div>
//                                 </div>
//                                 <div className="pricing-btn">
//                                     <Link to="#" className="axil-btn btn-large btn-borderd">Get Started Today</Link>
//                                 </div>
//                             </div>
//                             <div className="pricing-body">
//                                 <ul className="list-unstyled">
//                                     {data.facility.map((data, index) => (
//                                         <li key={index}><FaCheck /> {data}</li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>

//                 ))}
//                 </Carousel>
//             </div> */}
